import AuthStore from './authStore'
import UserProgramStore from './userProgramStore'
import ModalOnBoardingStore from './modalOnBoardingStore'

const stores = {
  authStore: AuthStore,
  userProgramStore: UserProgramStore,
  modalOnBoardingStore: ModalOnBoardingStore
}

export default stores
